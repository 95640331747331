import {Injectable} from '@angular/core';
import {TenantService} from './tenant.service';
import {Timestamp} from '@firebase/firestore';
import {from, Observable} from 'rxjs';
import {concatMap, filter, first, map} from 'rxjs/operators';
import {TenantInfo} from '../models/tenant.model';
import {PlatformPromo} from '../models/platform-promo.model';
import {findUniqueMatchWithId} from '../common/firestore-utils';
import { collection, collectionData, doc, Firestore, query, updateDoc, where } from '@angular/fire/firestore';

@Injectable({
  providedIn: 'root'
})
export class PlatformPromoService {

  constructor(private tenant: TenantService,
              private firestore: Firestore ) {
  }

  activatePlatformPromo(code: string): Observable<Partial<TenantInfo>> {
    console.log(`Loading platform promo ${code}`);
    return this.loadPlatformPromo(code)
      .pipe(
        filter(promo => promo?.active), // check if the promo is active before activating
        concatMap(promo => {

          console.log(`Loaded platform promo`, promo);

          const now = Timestamp.now()

          console.log(`Activating promo ${this.tenant.id}`);

          return from(updateDoc(doc(this.firestore, `tenants/${this.tenant.id}`), {
            platformPromoCode: code,
            platformPromoActivatedAt: now
          }))
            .pipe(
              map(() => {
                console.log(`Activated promo in tenant`);
                return {
                  platformPromoCode: code,
                  platformPromoActivatedAt: now,
                }
              })
            );
        })
      );


  }

  loadPlatformPromo(code:string): Observable<PlatformPromo> {

    const constraints = [
      where("code", "==", code),
      where("active", '==', true)
    ]

    const query$ = collectionData(query(collection(this.firestore, `platformPromos`), ...constraints), {idField: "id"}).pipe(first());

    return findUniqueMatchWithId<PlatformPromo>(query$)
      .pipe(
        first()
      );

  }

}
